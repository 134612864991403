<template>
  <div>
    <div class="bussinessContent">
      <el-checkbox-group
          :max="maxNumber?maxNumber:100"
          v-model="Conventional"
          size="mini"
          @change="change"
      >
        <el-checkbox-button

            v-for="city in showLine"
            :label="city.id"
            :key="city.id"
            size="small"
        >{{ city.name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="clearfix">
      <el-button
          type="primary"
          size="small"
          class="fr"
          @click="saveLine"
          :loading="loading"
      >确认
      </el-button>
      <el-button
          type="text"
          size="small"
          class="fr mr15"
          @click="clickCancel"
      >取消
      </el-button>
    </div>
  </div>
</template>

<script>
import {shippingLine} from '~bac/utils/enumValue'

export default {
  name: "majorLine",
  props:['isSelect','number'],
  data() {
    return {
      showLine: [],
      Conventional: [],
      loading: false,
      disabled: false,
      maxNumber: 0
    }
  },
  watch: {
    isSelect() {
      this.Conventional = this.isSelect;
    }
  },
  mounted() {
    if (this.LOCALE === "en") {
      shippingLine.forEach(element => {
        element.name = element.label_en;
      });
    } else {
      shippingLine.forEach(element => {
        element.name = element.label_zh;
      });
    }
    this.showLine = shippingLine
    let haveLine = this.isSelect;
    if (haveLine) {
      this.Conventional = haveLine.map(item => {
        return item;
      });
    }
    this.maxNumber = this.number
    if (haveLine.length > this.number) {
      this.maxNumber = haveLine.length
    }
  },
  methods: {
    change(e) {
      if (e.length < this.number + 1) {
        this.maxNumber = this.number;
      } else {
        this.maxNumber = e.length;
      }
    },
    clickCancel() {
      this.$emit("cancelLine");
    },
    saveLine() {
      if (this.Conventional.length > 5) {
        this.$message({
          showClose: true,
          message: this.LOCALE == "en" ? "Select up to five" : "最多选择五个",
          type: "warning"
        });
        return;
      }
      this.$emit("saveLine", this.Conventional);
    }
  }
}
</script>

<style scoped lang="less">
.mr15{
  margin-right: 15px;
}
</style>